import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

const Experience = ({ data, shield_data, legend_data, sample_size_data }) => {
    
    const [wave1, setWave1] = useState(false);
    const [wave2, setWave2] = useState(false);
    const [chartHeight, setchartHeight] = useState(270);


    data = data[0];
   
    const sampleSize = sample_size_data[0];
    const banks = legend_data.map(item => item.name);
    const colors = legend_data.map(item => item.color);

    const prioritizeStanbic = (data) => {
        const prioritizedData = {};

        Object.keys(data).forEach(statement => {
            const bankData = data[statement];
            if (bankData['Stanbic Bank']) {
                const stanbicData = bankData['Stanbic Bank'];
                const { 'Stanbic Bank': _, ...restBanks } = bankData;
                prioritizedData[statement] = { 'Stanbic Bank': stanbicData, ...restBanks };
            } else {
                prioritizedData[statement] = bankData;
            }
        });

        return prioritizedData;
    };

    const prioritizedData = prioritizeStanbic(data);

    useEffect(() => {
        let wave1Exists = false;
        let wave2Exists = false;

        Object.keys(sampleSize).forEach(bank => {
            if (sampleSize[bank].hasOwnProperty('Wave 1')) {
                wave1Exists = true;
            }
            if (sampleSize[bank].hasOwnProperty('Wave 2')) {
                wave2Exists = true;
            }
        });

        setWave1(wave1Exists);
        setWave2(wave2Exists);
    }, [sampleSize]);

    const formatDataForChart = (data, bank) => {
        return Object.keys(data).map(statement => {
            const statementData = { statement };
            const bankData = data[statement][bank] || {};
            Object.keys(bankData).forEach(year => {
                statementData[year] = bankData[year];
            });
            const sample = sampleSize[bank] || {};
            statementData['Sample Size Wave 1'] = sample["Wave 1"] || 0;
            statementData['Sample Size Wave 2'] = sample["Wave 2"] || 0;
            return statementData;
        });
    };

    let loopCounter = 0;
    let chartWidth  = 0;
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '90% 10%', gridTemplateRows: '95% 5%', height: '90%', width: '100%' }}>
            <div style={{ display: 'flex', overflowX: 'none', gridColumn: '1 / 2' }}>
                {banks.map((bank, index) => {
                    const chartData = formatDataForChart(prioritizedData, bank);
                  
                    const timesToRender = index === 0 ? 2 : 1;

                    return [...Array(timesToRender)].map((_, i) => {
                        loopCounter++;
                        chartWidth = 70/banks.length;

                        return (
                            <div
                                key={`${bank}-${i}`}
                                style={{
                                    width: loopCounter === 1 ? '40%' : `${chartWidth}%`,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '15px'
                                                                  
                                }}
                            >
                                {loopCounter > 1 ? <div style={{ minHeight: '40px', width:'100%',paddingLeft: '10px', fontSize: '10px', textOverflow: 'ellipsis', textAlign: 'left' }}>{bank}</div> :
                                                    <div style={{ minHeight: '40px', color: 'transparent' }}>.</div>}
                                <ResponsiveContainer width="100%" height={chartHeight}>
                                    <BarChart data={chartData} layout="vertical">
                                        {loopCounter === 1 ? (
                                            <>
                                                <YAxis
                                                    type="category"
                                                    dataKey="statement"
                                                    width={400}
                                                    tickLine={false}
                                                    axisLine={false}
                                                    tick={{
                                                        fill: 'white', 
                                                        fontSize:'12px',
                                                        transform: 'translate(-100, 0)'
                                                    }}
                                                     />
                                               <XAxis type="number" domain={[0, 12]} hide />
                                            </>
                                        ) : (
                                            <>
                                                <YAxis
                                                    type="category"
                                                    dataKey="statement"
                                                    width={300}
                                                    tickLine={false}
                                                    axisLine={false}
                                                    tick={{
                                                        fill: 'white', 
                                                        fontSize:'12px',
                                                        transform: 'translate(-50, 0)'
                                                    }}
                                                    hide
                                                     />
                                                
                                                <XAxis type="number" domain={[0, 12]} hide />
                                        
                                        
                                        {Object.keys(chartData[0])
                                            .filter(key => key !== 'statement' && !key.startsWith('Sample Size'))
                                            .map((year, idx) => (
                                                <Bar
                                                    key={year}
                                                    dataKey={year}
                                                    fill={!wave2 ? legend_data.find(b => b.name === bank).color : year === 'Wave 1' ? 'white' : colors[index]}
                                                >
                                                    <LabelList 
                                                        dataKey={year}
                                                        position="right" 
                                                        fill="#fff"
                                                        fontSize={12}
                                                    />
                                                </Bar>
                                            ))}
                                            </>
                                        )}
                                        
                                       {loopCounter > 1 ?
                                        (<Legend
                                            content={() => (
                                            <div style={{ listStyleType: 'none', padding: 0, marginTop: '10px' }}>
                                                <div style={{width: '200px', color: '#fff', fontSize: '10px', display: 'flex' }}>
                                                    <div style={{ width: '8px', height: '8px', backgroundColor: !wave2? legend_data.find(b => b.name === bank).color : '#fff', marginRight: '5px' }}></div>
                                                    {`Wave 1 (n=${sampleSize[bank]?.["Wave 1"] || 0})`}
                                                </div>
                                                {wave2 && (
                                                    <div style={{width: '200px',  color: '#fff', fontSize: '10px', display: 'flex' }}>
                                                        <div style={{ width: '8px', height: '8px', backgroundColor: legend_data.find(b => b.name === bank).color, marginRight: '5px' }}></div>
                                                        {`Wave 2 (n=${sampleSize[bank]?.["Wave 2"] || 0})`}
                                                    </div>
                                                )}
                                            </div>
                                            )}
                                        />):
                                        (<Legend
                                            content={() => (
                                                <div style={{ listStyleType: 'none', padding: 0 , marginTop: '10px' }}>
                                                    <div style={{ width: '200px',color: 'transparent', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '8px', height: '8px', backgroundColor:  'transparent' }}></div>
                                                        .
                                                    </div>
                                                    {wave2 && (
                                                        <div style={{ width: '200px', color: 'transparent', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ width: '8px', height: '8px', backgroundColor:  'transparent'}}></div>
                                                            .
                                                        </div>
                                                    )}
                                                </div>
                                                )}
                                        />)
                                        }
                                       
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        );
                    });
                })}
            </div>

            <div style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                gridColumn: '2 / 3',
            }}>
                <SmallShield 
                    scale='80%'
                    waveTxt={shield_data.waveTxt}
                    fill={shield_data.fill}
                />
            </div>
        </div>
    );
};

export default Experience;
