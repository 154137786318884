import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';

const Wealth = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const sectionColor = "linear-gradient(45deg, rgba(45,80,145, 0.8) 0%, rgba(45,80,145, 0.8) 100%)"; 

    const section_obj = {
        name: 'Wealth',
        url_part: 'wealth_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.country, section_obj);

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    const charts = [
        {
            // Line Graph - Graph1
            title: company.toUpperCase() + ' NPS',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            icon: 'trending_up'
        },
        {   
            // Stacked BarGraph - Graph2
            title: company.toUpperCase() + ' NPS RATING DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'speed'
        },
        {
            // SideBar Graph - Graph3
            title: 'OVERALL SATISFACTION WITH ' + company.toUpperCase(),
            chart_type: 'satisfaction_bars',
            sectionTitleColor : sectionColor,
            ...data.satisfaction,
            colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'theater_comedy'
            
        },
        {
            // Line Graph - Graph4
            title: company.toUpperCase() + ' EXPERIENCE SCORE',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.sb_exp_score,
            icon: 'diversity_3'
        },
        {   
            // Column Graph - Graph5
            title: 'DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.delivery_experience,
            icon: 'verified'
        },
        // {
        //     // SideBar Graph - Graph6
        //     title: 'OVERALL SATISFACTION WITH ON-SHORE',
        //     chart_type: 'satisfaction_bars',
        //     sectionTitleColor : sectionColor,
        //     data: [
        //         { name: 'Rating 1', Wave1: 7, Wave2: 1},
        //         { name: 'Rating 2', Wave1: 5.4, Wave2: 4},
        //         { name: 'Rating 3', Wave1: 0, Wave2: 0 },
        //         { name: 'Rating 4', Wave1: 1, Wave2: 3},
        //         { name: 'Rating 5', Wave1: 24, Wave2: 17.88},
        //         { name: 'Rating 6', Wave1: 0, Wave2: 0},
        //         { name: 'Rating 7', Wave1: 23, Wave2: 0},
        //         { name: 'Rating 8', Wave1: 46.2, Wave2: 0 },
        //         { name: 'Rating 9', Wave1: 64, Wave2: 0},
        //         { name: 'Rating 10', Wave1: 0, Wave2: 0},
        //         ],
        //     shield_data: {waveTxt:"Market" , fill : "blue" },    
        //     chart_shield:[
        //           {waveTxt:"Market" ,waveSample : "20" , fill : "green" },
        //           {waveTxt:"Market" ,waveSample: "100" , fill : "orange"}
        //       ],
        //     colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
        //     icon: 'trending_up'
            
        // },
        // {
        //     // SideBar Graph - Graph7
        //     title: 'OVERALL SATISFACTION WITH OFF-SHORE',
        //     chart_type: 'satisfaction_bars',
        //     sectionTitleColor : sectionColor,
        //     data: [
        //         { name: 'Rating 1', Wave1: 7, Wave2: 1},
        //         { name: 'Rating 2', Wave1: 5.4, Wave2: 4},
        //         { name: 'Rating 3', Wave1: 0, Wave2: 0 },
        //         { name: 'Rating 4', Wave1: 1, Wave2: 3},
        //         { name: 'Rating 5', Wave1: 24, Wave2: 17.88},
        //         { name: 'Rating 6', Wave1: 0, Wave2: 0},
        //         { name: 'Rating 7', Wave1: 23, Wave2: 0},
        //         { name: 'Rating 8', Wave1: 46.2, Wave2: 0 },
        //         { name: 'Rating 9', Wave1: 64, Wave2: 0},
        //         { name: 'Rating 10', Wave1: 0, Wave2: 0},
        //         ],
        //     shield_data: {waveTxt:"Market" , fill : "blue" },    
        //     chart_shield:[
        //         {waveTxt:"Market" ,waveSample : "20" , fill : "green" },
        //         {waveTxt:"Market" ,waveSample: "100" , fill : "orange"}
        //         ],
        //     colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
        //     icon: 'trending_up'
            
        // },
        {
            // Line graph with details - Graph8
            title: 'WHAT MATTERS IN BANKING',
            chart_type: 'matters_linegraph_with_detail',
            sectionTitleColor : sectionColor,
            ...data.what_matters,
            // colors:['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'psychology'
        },
    ]
    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
        />
    );
};

export default Wealth;
