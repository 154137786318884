import React from "react";

const WeightedNote = () => {
    return (
        <div style={{ position: 'absolute', fontSize: '12px', left: '0px', bottom: '-10px'}}>
            <i>The <b style={{ fontSize: '20px' }}>*</b> indicates weighted results</i>
        </div>
    )
}

export default WeightedNote