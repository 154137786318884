import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/login.css';
import constants from '../Usables/Constants';

const Login = (props) => {
    // console.log(constants.API_URL);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user_type, setUser_type] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('isLoggedIn', false);
        props.setIsLoggedIn(false);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const userData = { email, password };
        fetch(constants.API_URL + 'user_auth/login/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        })
        .then(response => {
          // Handle successful login here
          if (response.ok) {
            response.text().then(text => {
                let user = JSON.parse(text);
                // console.log(user);
                if (!user.hasOwnProperty('non_field_errors')){
                    props.setIsLoggedIn(true);
                    localStorage.setItem('isLoggedIn', true);
                    if (!user.is_staff && user.country!='South Africa') {
                        navigate('/home',  { state: { country:user.country, user } }); // Redirect to home page
                    }else{
                        navigate("/choose-country",  { state: { user } });
                    }
                }else{
                    alert(user.non_field_errors)
                }
                
            })
          }
        })
        .catch(error => {
          // Handle login error here
          console.log(error);
        });
      }

    return (
        <div>
            {/* <h2>Login</h2>
            <button onClick={handleLogin}>Login</button> */}

            <div className="section"></div>
            <div className="login-box">
                <form onSubmit={handleSubmit} className="col s12">
                    <div id="login_emblem">
                        <div className="le_line" id="le_line_left"></div>
                        <div id="le_circle">
                            <i className="material-icons user-icon le_icon">person_outline</i>
                        </div>
                        <div className="le_line" id="le_line_right"></div>
                    </div>
                    <div className="textbox-cont">
                        <div className="icon-cont">
                            <i className="material-icons user-icon">person_outline</i>
                        </div>
                        <input type="email" name="uid" id="uid" className="validate" required placeholder="Username" value={email} onChange={
                            (e) => {
                                setEmail(e.target.value);
                                if (email.includes("plus94")) {
                                    setUser_type('p94_admin');
                                }else {
                                    setUser_type('client');
                                }
                            } 
                    } />
                    </div>
                    <div className="textbox-cont">
                        <div className="icon-cont">
                            <i className="material-icons user-icon">lock_outline</i>
                        </div>
                        <input type="password" name="pwd" id="pwd" className="validate" required placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <input type="submit" name="login" value="Sign In" />
                </form>
                {/* Display login error here */}
            </div>
        </div>
    );
};

export default Login;
