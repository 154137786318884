import { XAxis, YAxis, ResponsiveContainer, Legend, Tooltip, LineChart, Line } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';
import WeightedNote from '../../Usables/WeightedNote';

function LineCharts({ data, lineColors, shield_data, sample_size }) {
    const is_weighted = Object.keys(data[0]).some(name => name.includes('*'))

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const currentSample = sample_size.find(sample => sample.name === label);
    
            return (
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px', color: '#000', fontWeight: 'bold' }}>
                    <p style={{ marginBottom: '5px', fontSize: '14px' }}>{label}</p>
                    {payload.map((entry, index) => {
                        const sampleText = currentSample ? currentSample[entry.dataKey] : 'N/A';
                        return (
                            <p
                                key={`item-${index}`}
                                style={{ color: entry.stroke, margin: '5px 0', fontSize: '12px' }} // Adjust spacing and text size here
                            >
                                {`${entry.name}: ${entry.value} (n = ${sampleText})`}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    };
    

    function CustomDot(props) {
        const { cx, cy, stroke, value } = props;
    
        return (
            <svg x={cx - 10} y={cy - 10} width={50} height={50} fill={stroke} viewBox="0 0 1024 1024">
                <rect x="0" y="0" width="500" height="500" />
                <text x="250" y="250" fill="white" fontSize="200" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </svg>
        );
    }

    const side_space = 250;
    const colors = lineColors;

    const keysFromObject = Object.keys(data[0]);
    keysFromObject.shift(); //removing the first key (name)

    return (
        <div style={{ position: 'relative', width: '100%', height: '90%' }}>
            <ResponsiveContainer minWidth={600} >
                <LineChart data={data}>
                    <XAxis 
                        dataKey="name" 
                        tickLine={{ stroke: 'white' }} 
                        axisLine={{ stroke: '#ffffff' }} 
                        padding={{ right: side_space, left: side_space }} 
                        tick={{ fill: 'white' }} 
                        fontSize={16} 
                    />
                    <YAxis 
                        tickLine={false} 
                        axisLine={false} 
                        margin={10} 
                        tick={{ fill: '#ffffff' }} 
                        fontSize={14}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Legend 
                        verticalAlign="top"
                        layout="vertical"
                        align="right"
                        iconType="Square"
                        formatter={(value) => (
                            <span style={{ color: 'white' }}>
                                {value}
                            </span>
                        )}
                    />
                    {keysFromObject.map((item, index) => (
                        <Line 
                            key={index} 
                            dataKey={item} 
                            stroke={colors[index]} 
                            strokeWidth={5} 
                            fill={colors[index]} 
                            dot={<CustomDot />} 
                            activeDot={false} 
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>

            <div style={{ 
                position: 'absolute', 
                bottom: '0', 
                right: '0', 
                margin: '10px', // Optional: adds margin around the SmallShield
            }}>
                <SmallShield 
                    scale='80%'
                    fill={shield_data.fill}
                    waveTxt={shield_data.waveTxt}
                />
            </div>
            {is_weighted &&
                <WeightedNote />
            }
        </div>
    );
}

export default LineCharts;
