import { React, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import constants from '../Usables/Constants';
import ChartsComponent from './ChartsComponent';
import useDataFetcher from './useDataFetcher';

const PPBTotalResults = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const sectionColor = undefined; 

    const section_obj = {
        name: 'PPB Total',
        url_part: 'ppb_total_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.country, section_obj);

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    console.log(data);

    const charts = [
        {   // Line Graph
            title: company.toUpperCase() + ' NPS SCORE',
            chart_type: 'line',
            sectionTitleColor: sectionColor,
            icon: 'speed',
            ...data.nps
        },
        {   // Stacked bar graph
            title: company.toUpperCase() + ' NPS RATING DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor: sectionColor,
            icon: 'vertical_distribute',
            ...data.rdist
        },
        {   // Bar Graph with a line
            title: company.toUpperCase() + ' RECOMMENDATION',
            chart_type: 'column',
            sectionTitleColor: sectionColor,
            icon: 'trending_up',
            ...data.bank_recommendation
        },
        {   // SideBar Graph 
            title: 'OVERALL SATISFACTION WITH ' + company.toUpperCase(),
            chart_type: 'satisfaction_bars',
            sectionTitleColor: sectionColor,
            data: data.satisfaction.data,
            sample_size_data: data.satisfaction.sample_size_data,
            shield_data: data.satisfaction.shield_data,
            chart_shield: data.satisfaction.chart_shield,
            colors: ['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'theater_comedy'
        }
    ];

    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
        />
    );
};

export default PPBTotalResults;
