import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const BlueBar = ({ imgSize, Text, BarHeight, FillColor, Icon }) => {
    const imageHeight = BarHeight===undefined? imgSize*0.1: BarHeight; //
    const fill_color = FillColor===undefined? "linear-gradient(45deg, rgba(0, 0, 139, 0.8) 0%, rgba(100, 120, 200, 0.8) 100%)": FillColor;
    const icon_text = Icon!=='Default'? Icon : 'circle';

    return (
        <div style={{ 
            width: `${imgSize*0.9}%`, 
            height: `${imageHeight}px`, 
            background: fill_color, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            color: 'white', 
            fontSize: `${imageHeight*0.4}px`, 
            fontWeight: 'bold' 
        }}>
            {!Icon ? Text : <span className="material-icons" style={{ fontSize: `${imageHeight*1}px` }}>{icon_text}</span> }
        </div>
    );
};

export default BlueBar;
