import React, { useState } from 'react';
import std_emblem from '../imgs/std_emblem.png'; 
import '../styles/TopBar.css';
import constants from './Usables/Constants';

const TopBar = ({country}) => {
    const year = new Date().getFullYear();
    const [category] = useState(''); // Replace with the category
    const [path] = useState('../'); // Replace with the path to your images
    
    const company = constants.reporting_countries.hasOwnProperty(country)? constants.reporting_countries[country].company:'Standard Bank';
    return (
        <nav className="TopBar" >
            <div className="headers">
                <div className="h-item">
                    <div className='flexing center_v_and_h' style={{ paddingLeft: '5vw' }}>
                        <div>
                            <img className="LogoPart responsive-img" src={`${std_emblem}`} alt=""/>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <div style={{ fontSize: 'xx-large', }}><b>{company}</b></div>
                            <div style={{ color: 'transparent' }}>.</div>
                        </div>
                    </div>
                </div>
                <div className="h-item">
                    <div className="NavText"> 
                        <span><b>| AFRICA REGIONS NPS 2021 - {year}</b></span>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default TopBar;