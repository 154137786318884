import React from 'react';
import '../../styles/ChooseCountry.css'; // Importing CSS for styling
import { useNavigate, useLocation } from 'react-router-dom';
import constants from '../Usables/Constants'
import Flag from '../Usables/Flag';
import CapitalFirstLetter from '../Usables/CapitalFirstLetter';

function ChooseCountry() {

    const navigate = useNavigate();
    const location = useLocation();

    const handleSelect = (country) => {
        navigate(`/home`, { state:{ country:country, user:location.state.user } });
    };

    const handleFlagClick = (country) => {
        const formatted_country = CapitalFirstLetter(country);
        if (constants.reporting_countries.hasOwnProperty(formatted_country)) {
            handleSelect(formatted_country);
        }
        else {
            alert('Not reporting!');
        }
    };
  return (
    <div className="flag-container">
        <Flag img={constants.other_flags['Country Comparison'].img} country={'Overall'.toUpperCase()} onClick={handleFlagClick} />
        {Object.keys(constants.reporting_countries).map((country, index) => (
            <Flag key={(index+1)} img={constants.country_flags[country].img} country={country.toUpperCase()} onClick={handleFlagClick} />
        ))}
        <Flag img={constants.other_flags['Offshore'].img} country={'Offshore'.toUpperCase()} onClick={handleFlagClick} />
    </div>
  );
}

export default ChooseCountry;
