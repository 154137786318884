import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Previous_Button from '../imgs/Previous_Button.png';
import Home_button from '../imgs/Home_button.png';
import Final_Logout_Button from '../imgs/Final_Logout_Button.png';

import '../styles/nav_btns.css';

const NavigationButtons = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const { path, category } = props; // assuming path and category are passed as props

    const goBack = () => {
        navigate(-1);
    }

    const gotoMain = () => {
        navigate('/choose-country', { state:{ user:location.state.user } });
    }

    const goHome = () => {
        navigate('/home', { state:{ country:location.state.country, user:location.state.user } });
    }

    const logout = () => {
        navigate('/');
    }

    // console.log(location.state); // Access the state using location.state

    return (
        <div className="nav_btns">
            <div className="nav-btn-cont">
                <a className="nav-btn">
                    <img onClick={goBack} src={Previous_Button} height="100%" />
                </a>
            </div>

            {location.state.user.is_staff &&
                <div className="nav-btn-cont">
                    <a className="nav-btn" onClick={gotoMain}>
                        <span className="material-icons" style={{ fontSize: `24px` }}>flag</span>
                        <span style={{ fontSize: `12px`, marginLeft: '10px', 
                            textShadow: '0 0 8px rgba(25, 110, 255,0.2), 0 0 10px rgba(25, 110, 255,0.2), 0 0 12px rgba(25, 110, 255,0.2), 0 0 14px rgba(25, 110, 255,0.2), 0 0 16px rgba(25, 110, 255,0.2), 0 0 18px rgba(25, 110, 255,0.2), 0 0 20px rgba(25, 110, 255,0.2)' 
                            }}> 
                            Country Selection
                        </span>
                    </a>
                </div>
            }

            {location.state.country!==undefined &&
                <div className="nav-btn-cont">
                    <a className="nav-btn">
                        <img onClick={goHome} src={Home_button} height="100%" />
                    </a>
                </div>
            }

            <div className="nav-btn-cont">
                <a className="nav-btn">
                    <img onClick={logout} src={Final_Logout_Button} height="100%" />
                </a>
            </div>
        </div>
    );
}

export default NavigationButtons;
